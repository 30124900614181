import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import styled from "styled-components"
import Layout from "../components/layout"
import Seo from "../components/seo"
import BannerBG from "../images/service-bg.png"

const Banner = styled.section`
  background-image: url(${BannerBG});
  background-position: top;
  background-size: auto 100%;
  background-repeat: no-repeat;
  min-height: 60vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-end;
  text-align: center;

  h1 {
    font-size: ${props => props.theme.fontSize.h2};
  }
  h5 {
    width: 80%;
    margin: 0.5rem auto 2.5rem;
  }

  @media (min-width: 768px) {
    h1 {
      font-size: ${props => props.theme.fontSize.h1};
      margin-bottom: 1rem;
    }
    h5 {
      margin-bottom: 10vh;
    }
  }
  @media (min-width: 1024px) {
    min-height: 70vh;
    background-size: cover;
    h5 {
      width: 60%;
    }
  }
`
const CategoryTagsWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  background: ${props => props.theme.colors.secondary};

  a {
    text-decoration: none;
    margin: 2rem 1rem;
    color: white;
    font-weight: bold;
  }
`
const VideosSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 2rem 0;
  h2 {
    margin-bottom: 1rem;
    text-align: center;
  }
`
const Video = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0;

  h3 {
    margin-bottom: 1rem;
    text-align: center;
    font-size: ${props => props.theme.fontSize.h5};
    width: 80%;
  }

  iframe {
    width: 300px;
    height: auto;
  }
  @media (min-width: 768px) {
  }
  iframe {
    width: 640px;
    height: 360px;
  }
  @media (min-width: 1024px) {
  }
`
const ResourcesSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.colors.secondary};
  color: white;
  h2 {
    margin: 2rem 0 1rem;
    text-align: center;
  }
`
const ResourcesGrid = styled.div`
  @media (min-width: 768px) {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
  }
  @media (min-width: 1024px) {
    grid-template-columns: repeat(3, 1fr);
    max-width: 960px;
    margin: 0 auto;
  }
`
const Resource = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem 0;

  h3 {
    margin-bottom: 1rem;
    text-align: center;
    font-size: ${props => props.theme.fontSize.h5};
    width: 80%;
  }
`

const MediaPage = () => (
  <Layout>
    <Seo title="Media" />
    <Banner>
      <h1>Quantum Media</h1>
      <h5>
        Quantum Functional Medicine is a center for health and rejuvenation.
      </h5>
    </Banner>

    <CategoryTagsWrapper>
      <a href="#quantumVideos">Videos</a>
      <a href="#quantumResources">Resources</a>
    </CategoryTagsWrapper>

    <VideosSection id="quantumVideos">
      <h2>Quantum Videos</h2>
      <Video>
        <h3>1A Cancer Starts</h3>

        <iframe
          width="640"
          height="360"
          src="https://www.youtube-nocookie.com/embed/pi6HFt4nccs"
          title="1A Cancer Stats"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Video>

      <Video>
        <h3>Dr Winkler interview from the Cancer Answer Summit</h3>
        <iframe
          width="640"
          height="360"
          src="https://www.youtube-nocookie.com/embed/9qGJyBn9l0A"
          title="Interview from the Cancer Answer Summit"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Video>
      <Video>
        <h3>The Shortcomings of Modern (Allopathic) Medicine</h3>

        <iframe
          width="640"
          height="360"
          src="https://www.youtube-nocookie.com/embed/oNRjZAGktb8"
          title="Shortcomings of Modern (Allopathic) Medicine"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Video>

      <Video>
        <h3>
          What Modalities Should we Adopt to Treat Chronic and Degenerative
          Disease?
        </h3>

        <iframe
          width="640"
          height="360"
          src="https://www.youtube-nocookie.com/embed/rEhd1aXC0iw"
          title="What modalities should we adopt to treat chronic and degenerative
          disease?"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Video>
      <Video>
        <h3>Insights on Cancer Causes and Treatments</h3>

        <iframe
          width="640"
          height="360"
          src="https://www.youtube-nocookie.com/embed/sg9xMGmkXlY"
          title="Insights on Cancer Causes and Treatments"
          frameborder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowfullscreen
        ></iframe>
      </Video>
      {/* <Video>
        <h3>
          Nutrimedical Report Dr Bill Deagle MD Interview with Dr Winkler
          08/09/2017
        </h3>
        <iframe
          src="https://drive.google.com/file/d/1F8hTzkmZZLQTs-M0Eygg5Dq3OzCh6NTZ/preview"
          width="640"
          height="360"
          title="Nutrimedical Video"
        ></iframe>
      </Video> */}
    </VideosSection>
    <ResourcesSection id="quantumResources">
      <h2>Quantum Resources</h2>
      <ResourcesGrid>
        <Resource>
          <h3>Quantum Brochure</h3>

          <a
            href="https://drive.google.com/file/d/1Wsl6ZofS7uHJw3lrWPOpB19LmoiKafab/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/QFMbrochure_trifold_03-23-2017.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Quantum brochure"
              layout="constrained"
            />
          </a>
        </Resource>
        {/* <Resource>
          <h3>Stem Cell Therapy Brochure</h3>

          <a
            href="https://drive.google.com/file/d/1XoP2tFMuevoHTfo8TBR6Tt5hPAjKLZo5/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/stem-cells.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Stemm Cell Therapy"
              layout="constrained"
            />
          </a>
        </Resource> */}
        <Resource>
          <h3>Asyra Brochure</h3>

          <a
            href="https://drive.google.com/file/d/1Me94MWwwl2zx7ZcLFwPSexyX8qYO0Rq9/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/Asyra.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Asyra resource"
              layout="constrained"
            />
          </a>
        </Resource>

        <Resource>
          <h3>Tips to Help You Sleep</h3>

          <a
            href="https://drive.google.com/file/d/1DKQvpdMISy1qOOOdrhXTPsrPZIN5Dv1a/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/Tips_to_Help_You_Sleep(FLT009).jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Tips to help you sleep"
              layout="constrained"
            />
          </a>
        </Resource>

        <Resource>
          <h3>Thermography Brochure</h3>

          <a
            href="https://drive.google.com/file/d/1Jy9tZZPd9eVm9yxnLLTFymSPlD76bUX1/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/ThermographyBrochure.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Thermography Brochure"
              layout="constrained"
            />
          </a>
        </Resource>
        <Resource>
          <h3>Rejuvenation Brochure</h3>

          <a
            href="https://drive.google.com/file/d/1ZK3n0oiBW2-jecwG4jMiy0xwlLs6wTmi/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/Rejuvenation_low.compressed_purUS.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Rejuvenation Brochure"
              layout="constrained"
            />
          </a>
        </Resource>
        <Resource>
          <h3>Thymus Brochure</h3>

          <a
            href="https://drive.google.com/file/d/1YSYvcLtC6vvV0WcL4DyT_uJe_s8Q1z6u/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/Thymus_Low_compressed_greenUS.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Thymus Brochure"
              layout="constrained"
            />
          </a>
        </Resource>
        <Resource>
          <h3>Heavy Metal Brochure</h3>

          <a
            href="https://drive.google.com/file/d/1cdJc1ipkDTalusnoniWY3gtNg39LTq51/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/HeavyMetalBrochure.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Heavy Metal Brochure"
              layout="constrained"
            />
          </a>
        </Resource>
        <Resource>
          <h3>What is BIA?</h3>

          <a
            href="https://drive.google.com/file/d/1wkkAmAVP0ffqSJNfCaYHXQpJMbywkB4_/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/What_is_BIA_(MET1323-QFM)Logo.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="What is BIA?"
              layout="constrained"
            />
          </a>
        </Resource>
        <Resource>
          <h3>Quantum Life Issue 1</h3>

          <a
            href="https://drive.google.com/file/d/1gvADhAgT51q6ksSX5E5dyhR1uK19fJQD/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/2014_02_QLife_Issue_1.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Quantum Life Issue 1"
              layout="constrained"
            />
          </a>
        </Resource>
        <Resource>
          <h3>Quantum Life Issue 2</h3>

          <a
            href="https://drive.google.com/file/d/13NgTBr07r41CWipeG52wiGUA1ns1FV5B/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/2014_04_Quantum_Living_Issue_002.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Quantum Life Issue 2"
              layout="constrained"
            />
          </a>
        </Resource>
        <Resource>
          <h3>Quantum Newsletter</h3>

          <a
            href="https://drive.google.com/file/d/1nw-TUN1ppNg4tiqHkPyMA9mKb5Ovc1vw/view?usp=sharing"
            target="_blank"
            rel="noreferrer"
          >
            <StaticImage
              src="../resources/2014_ThdQT_Newsletter_003.jpg"
              width={250}
              quality={95}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="Quantum newsletter"
              layout="constrained"
            />
          </a>
        </Resource>
      </ResourcesGrid>
    </ResourcesSection>
  </Layout>
)

export default MediaPage
